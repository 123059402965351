//BRAND
export const name = 'AI Course Gen';
export const company = 'SkyWeb Inc';
export const websiteURL = 'https://app.aicoursegenerator.site';
export const serverURL = 'https://aicoursegen.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/ai-course-gen-d2a66.appspot.com/o/WhatsApp%20Image%202024-09-08%20at%2001.48.34.jpeg?alt=media&token=74f588aa-0cf4-4ea5-afc7-d49446604689';
export const razorpayEnabled = false;
export const paypalEnabled = false;
export const stripeEnabled = false;
export const paystackEnabled = true;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 7;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 72;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Kadiri Abiodun";
export const profession = 'SkyWeb Inc';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/ai-course-gen-d2a66.appspot.com/o/WhatsApp%20Image%202024-09-08%20at%2001.48.34.jpeg?alt=media&token=74f588aa-0cf4-4ea5-afc7-d49446604689';

//PAYPAL
export const paypalPlanIdOne = "P-1EM732768S920784HMWKW3OA";
export const paypalPlanIdTwo = "P-8T744865W27080359MWOCE5Q";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1OTo7CSDXmLtVnVeaHIHxqCj";
export const stripePlanIdTwo = "price_1OTo7eSDXmLtVnVeBbn82U5B";

//PAYSTACK
export const paystackPlanIdOne = "PLN_7aec5zu9da4gab2";
export const paystackPlanIdTwo = "PLN_43siudrzjq8dxgx";
export const amountInZarOne = '124';
export const amountInZarTwo = '1285';